import React from "react"
import { Box, Flex, Link as LinkExt, SimpleGrid, Stack } from "@chakra-ui/react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import WufooForm from "react-wufoo-embed"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Slider from "react-slick"
import "../../../utils/slick.css"
import "../../../utils/slick-theme.css"

import {
  SectionHeading,
  VideoPlayer,
  ArrowLeft,
  ArrowRight,
  PageLabel,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

import {
  Container,
  Layout,
  Section,
  Main,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  BonniesNewsGrid,
  Calendar,
  ProgramsListing,
  SecondaryPagesHero,
  Seo,
  SidebarLinks,
} from "../../../components/elements"

const Program = ({ data, pageContext }) => {
  const page = data.datoCmsCancerProgram
  const {
    breadcrumb: { crumbs },
  } = pageContext

  const settings = {
    adaptiveHeight: "true",
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <ArrowLeft color="white" />,
    nextArrow: <ArrowRight color="white" />,
  }

  return (
    <Layout>
      <Seo title={page.title} />

      <SecondaryPagesHero page={page}>
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" / "
          crumbLabel={page.title}
        />
      </SecondaryPagesHero>

      <Container width="full">
        <Stack
          direction={["column", "column", "row"]}
          spacing={12}
          py={[9, 9, 12, 16]}
        >
          <Main width={["full", "full", "2 / 3"]}>
            {/* Content */}
            <Flex flexWrap="wrap">
              <Box width="full">
                {page.video && (
                  <Box sx={{ mb: 6 }}>
                    <VideoPlayer url={page.video.url} />
                  </Box>
                )}

                <Box
                  sx={{ width: ["full"] }}
                  dangerouslySetInnerHTML={{
                    __html: page.contentNode.childMarkdownRemark.html,
                  }}
                />

                {page.subprograms && (
                  <SimpleGrid columns={[1, 1, 2]} spacing={[6]} mt={12}>
                    {page.subprograms.map(({ ...subprogram }) => (
                      <Stack
                        direction="column"
                        justifyContent="space-between"
                        height="full"
                        sx={{ a: { textDecoration: "none" } }}
                      >
                        <Link to={subprogram.slug}>
                          <PageLabel
                            borderLeftColor="blue.500"
                            borderLeftWidth={6}
                            p={3}
                            m={0}
                            width="full"
                            height="full"
                          >
                            <Box as="h3" lineHeight={1} mb={0} py={3}>
                              {subprogram.title}
                            </Box>
                            <FontAwesomeIcon
                              icon={faChevronRight}
                              className="fa-lg"
                            />
                          </PageLabel>
                        </Link>
                      </Stack>
                    ))}
                  </SimpleGrid>
                )}

                {page.images.length > 0 ? (
                  <Box
                    mb={6}
                    maxWidth="680px"
                    sx={{
                      ".slick-arrow": {
                        backgroundColor: "blackAlpha.600",
                      },
                    }}
                  >
                    <Slider {...settings}>
                      {page.images.map(({ gatsbyImageData, url }) => (
                        <Box
                          key={url}
                          sx={{
                            ".gatsby-image-wrapper-constrained": {
                              display: "block",
                            },
                          }}
                        >
                          <GatsbyImage image={gatsbyImageData} alt="slide" />
                        </Box>
                      ))}
                    </Slider>
                  </Box>
                ) : null}

                {pageContext.slug === "bonnies-bus" && (
                  <>
                    <Box my={12} mx={-1}>
                      <WufooForm userName="wvuhsc" formHash="zb6grvz1heelqk" />
                    </Box>

                    {/* Calendar */}
                    <Section id="news">
                      <Container>
                        <Flex sx={{ flexWrap: "wrap" }}>
                          <Box sx={{ width: ["full"], textAlign: "center" }}>
                            <SectionHeading>Calendar of Events</SectionHeading>
                          </Box>
                          <Calendar />

                          <Box
                            sx={{
                              pt: 4,
                              textAlign: "right",
                              width: ["full"],
                            }}
                          >
                            <LinkExt href="https://calendar.wvu.edu/site/wvucancerinstitute/">
                              See Full Calendar{" "}
                              <FontAwesomeIcon icon={faChevronRight} />
                            </LinkExt>
                          </Box>
                        </Flex>
                      </Container>
                    </Section>
                  </>
                )}

                {pageContext.slug === "lung-cancer-screening-program" && (
                  <Box mt={12} mx={-1}>
                    <WufooForm
                      userName="wvuhealthcare"
                      formHash="zvsa5780j5rb59"
                    />
                  </Box>
                )}
              </Box>
            </Flex>
          </Main>

          <Box
            as="aside"
            id="sidebar"
            bg="lightgray"
            width={["full", "full", 1 / 3]}
          >
            <Box p={[3, 3, 6]}>
              <Box as="h3">A Closer Look</Box>
              <SidebarLinks page={page} />
              <ProgramsListing />
            </Box>
          </Box>
        </Stack>

        {pageContext.slug === "mobile-cancer-screening-program" && (
          <Section>
            <Box sx={{ width: ["full"], textAlign: "center" }}>
              <SectionHeading>News</SectionHeading>
            </Box>

            <BonniesNewsGrid />

            <Box sx={{ pt: 4, textAlign: "right", width: ["full"] }}>
              <LinkExt href="https://wvumedicine.org/news-feed/?sec=Bonnies-Bus">
                See More Stories <FontAwesomeIcon icon={faChevronRight} />
              </LinkExt>
            </Box>
          </Section>
        )}
      </Container>
    </Layout>
  )
}

export default Program

export const query = graphql`
  query programPageQuery($slug: String!) {
    datoCmsCancerProgram(slug: { eq: $slug }) {
      id
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData(imgixParams: { fit: "crop", crop: "focalpoint" })
      }
      meta {
        status
      }
      linksNode {
        childMarkdownRemark {
          html
        }
      }
      video {
        url
      }
      images {
        gatsbyImageData(
          layout: CONSTRAINED
          imgixParams: { fit: "crop", crop: "focalpoint" }
        )
        url
      }
      subprograms {
        heroImage {
          gatsbyImageData
        }
        title
        slug
      }
      seo {
        description
        title
        image {
          url
        }
      }
      title
      slug
    }
  }
`
